// @mui
import PropTypes from 'prop-types'
import React from "react";
import { Card, Typography, CardHeader, CardContent } from "@mui/material";
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
} from "@mui/lab";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

// ----------------------------------------------------------------------

AppOrderTimeline.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppOrderTimeline({
  title,
  subheader,
  list,
  reportStatus,
  ...other
}) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      <div hidden={reportStatus !== "Loading"}>
        <Box
          sx={{ display: "flex", align: "center" }}
          style={{ display: "flex", "justify-content": "center" }}
        >
          <CircularProgress />
        </Box>
      </div>
      <div hidden={reportStatus === "Loading" || list.length !== 0}>
        <Box
          sx={{ display: "flex", align: "center" }}
          style={{
            display: "flex",
            "justify-content": "center",
            "margin-top": "25px",
          }}
        >
          No history found.
        </Box>
      </div>
      <CardContent
        sx={{
          "& .MuiTimelineItem-missingOppositeContent:before": {
            display: "none",
          },
        }}
      >
        <Timeline>
          {list.map((item, index) => (
            <OrderItem
              key={item.id}
              item={item}
              isLast={index === list.length - 1}
            />
          ))}
        </Timeline>
      </CardContent>
    </Card>
  );
}

// ----------------------------------------------------------------------

OrderItem.propTypes = {
  isLast: PropTypes.bool,
  item: PropTypes.shape({
    time: PropTypes.instanceOf(Date),
    title: PropTypes.string,
    type: PropTypes.string,
  }),
}

function OrderItem({ item, isLast }) {
  const { type, title, time } = item
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          color={
            (title === 'Typical' && 'secondary') ||
              (title === 'Ideal' && 'success') ||
              (title === 'Crisis' && 'warning') ||
            (title === 'Disengaged' && 'grey') ||
              (title === 'Toxic' && 'error') ||
            'error'
          }
        />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>

      <TimelineContent>
        <Typography variant="subtitle2">{title}</Typography>

        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {time}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  )
}
