// component
import Iconify from '../../components/Iconify'
import UserAccess from "src/components/Auth/UserAccess";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

let navConfig = [
  {
    title: "myEmotions",
    path: "/dashboard/myEmotions",
    icon: getIcon("eva:home-outline"),
  },
  {
    title: "teamEmotions",
    path: "/dashboard/teamEmotions",
    icon: getIcon("eva:people-fill"),
  },
];

export default navConfig
