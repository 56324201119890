import { Navigate, useRoutes } from 'react-router-dom'
// layouts
import DashboardLayout from './layouts/dashboard'
import LogoOnlyLayout from './layouts/LogoOnlyLayout'
//
import NotFound from './pages/Page404'
import MyEmotions from './pages/MyEmotions'
import TeamEmotions from './pages/TeamEmotions'
import UserAccess from './components/Auth/UserAccess'
import UserId from './components/Auth/UserId'
import UserName from './components/Auth/UserName'

// ----------------------------------------------------------------------

export default function Router() {
  const accessLevel = UserAccess()
  const currentUser = UserId()
  const userName = UserName()

  let routesObj

  console.log('Access Level in routes', accessLevel)
  if (accessLevel === 'Admin') {
    routesObj = [
      {
        path: '/dashboard',
        element: <DashboardLayout />,
        children: [
          { path: 'myEmotions', element: <MyEmotions /> },
          { path: 'teamEmotions', element: <TeamEmotions /> },
        ],
      },
      {
        path: '/',
        element: <LogoOnlyLayout />,
        children: [
          { path: '/', element: <Navigate to="/dashboard/myEmotions" /> },
          { path: '404', element: <NotFound /> },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      { path: '/', element: <Navigate to="/404" replace /> },
    ]
  } else {
    routesObj = [
      {
        path: '/dashboard',
        element: <DashboardLayout />,
        children: [{ path: 'myEmotions', element: <MyEmotions /> }],
      },
      {
        path: '/',
        element: <LogoOnlyLayout />,
        children: [
          { path: '/', element: <Navigate to="/dashboard/myEmotions" /> },
          { path: '404', element: <NotFound /> },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      { path: '/', element: <Navigate to="/404" replace /> },
    ]
  }
  console.log('Routes Oject', routesObj)

  return useRoutes(routesObj)
}
