// routes
import Router from './routes'
import React, { useState, useEffect } from 'react'
// theme
import ThemeProvider from './theme'
// components
import ScrollToTop from './components/ScrollToTop'
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart'

import Amplify, { Auth, Hub } from 'aws-amplify'

import UserAccess from 'src/components/Auth/UserAccess'
import UserId from 'src/components/Auth/UserId'

// ----------------------------------------------------------------------

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-east-1_xxWUK1Sor', //CopsHome

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '4s8eb0qsnrdolnq6ofsugr05t', //CopsHome_NoSecret

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: 'copshomefederate.auth.us-east-1.amazoncognito.com', //CopsHome
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
      redirectSignIn: process.env.REACT_APP_REDIRECT_URL,
      redirectSignOut:
        'https://aquiline-react-static.s3.amazonaws.com/login/oauth2/code/cognito',
      responseType: 'code', // REFRESH token will only be generated when the responseType is code
    },
  },
})

export default function App() {
  const [user, setUser] = useState(null)

  let [userData, setUserData] = useState(null)

  useEffect(() => {
    getUser().then((userData) => setUserData(userData))
  }, [])

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => console.log('userinfoAppJs', userData))
      .catch(() => Auth.federatedSignIn())
  }
  const accessLevel = UserAccess()

  console.log('Access Level', UserAccess(), UserId())
  console.log('Access.js --UserAccess', UserAccess(), accessLevel)

  return (
    <ThemeProvider>
      <ScrollToTop />
      <Router />
    </ThemeProvider>
  )
}
