// component
import Iconify from '../../components/Iconify'

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />

const associateNavConfig = [
  //   {
  //     title: 'home',
  //     path: '/dashboard/home',
  //     icon: getIcon('eva:home-outline'),
  //   },

  {
    title: 'myEmotions',
    path: '/dashboard/myEmotions',
    icon: getIcon('eva:home-outline'),
  },

  //   {
  //     title: 'teamEmotions',
  //     path: '/dashboard/teamEmotions',
  //     icon: getIcon('eva:people-fill'),
  //   },
  //   {
  //     title: 'myEmotionsRef',
  //     path: '/dashboard/myEmotionsRef',
  //     icon: getIcon('eva:person-outline'),
  //   },
  //   {
  //     title: 'dashboard',
  //     path: '/dashboard/app',
  //     icon: getIcon('eva:pie-chart-2-fill'),
  //   },
  //   {
  //     title: 'user',
  //     path: '/dashboard/user',
  //     icon: getIcon('eva:people-fill'),
  //   },
  //   {
  //     title: 'product',
  //     path: '/dashboard/products',
  //     icon: getIcon('eva:shopping-bag-fill'),
  //   },
  //   {
  //     title: 'blog',
  //     path: '/dashboard/blog',
  //     icon: getIcon('eva:file-text-fill'),
  //   },
  //   {
  //     title: 'login',
  //     path: '/login',
  //     icon: getIcon('eva:lock-fill'),
  //   },
  //   {
  //     title: 'register',
  //     path: '/register',
  //     icon: getIcon('eva:person-add-fill'),
  //   },
  //   {
  //     title: 'Not found',
  //     path: '/404',
  //     icon: getIcon('eva:alert-triangle-fill'),
  //   },
]

// } else {
//   navConfig = [
//     {
//       title: 'myEmotions',
//       path: '/dashboard/myEmotions',
//       icon: getIcon('eva:home-outline'),
//     },

//     //     {
//     //       title: 'teamEmotions',
//     //       path: '/dashboard/teamEmotions',
//     //       icon: getIcon('eva:people-fill'),
//     //     },
//     {
//       title: 'myEmotionsRef',
//       path: '/dashboard/myEmotionsRef',
//       icon: getIcon('eva:person-outline'),
//     },
//   ]
// }

export default associateNavConfig
