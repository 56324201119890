import UserAttributes from './UserAttributes'

const UserManager = () => {
  let userId, uID, manager, managerDetails
  const userAttributes = UserAttributes()
  managerDetails = 'unknown'
  if (userAttributes != null) {
    const managerInfo = userAttributes['custom:manager']
    console.log('Manager', managerInfo)
    manager = managerInfo.split(',')[0].replace('(', '').replace(')', '')
    managerDetails = manager.replace('cn=', '').split(' ')
  }
  return managerDetails
}
export default UserManager
