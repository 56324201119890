import UserAttributes from './UserAttributes'
import User from './User'
import { common } from '@mui/material/colors'

const UserAccess = () => {
  let userId, uID
  const userAttributes = UserAttributes()
  const user = User()
  if (user != null) {
    userId = user.username
    userId = userId.replace('@amazon.com', '')
    userId = userId.replace('copshomeusers_', '')
    uID = userId
  }
  console.log('User in Access', uID)
  var access = 'Tester'

  if (userAttributes != null) {
    const ldapGroups = userAttributes['custom:groups']
    const manager = userAttributes['custom:manager']
    let city = userAttributes['custom:amzn_city']
    console.log('PrintingUserAccess_LDAPGroups', ldapGroups)
    console.log('PrintingUserAccess_Manager', manager, city)

		//admin ldap group to be replaced with adect-sentimeter-admin
    if (ldapGroups && ldapGroups.includes('adect-notifier-admin')) {
      access = 'Admin'
      console.log('PrintingUserAccess_', access)
    }
  }
  return access
}

export default UserAccess
