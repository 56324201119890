import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { styled } from '@mui/material/styles'
import 'font-awesome/css/font-awesome.css'

import {
  faSmile,
  faCircleCheck,
  faFaceSadCry,
  faFrown,
  faFaceGrin,
  faFaceDizzy
} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Button,
  Typography,
  Container,
  Box,
  Grid,
  Stack, CardContent,
} from '@mui/material'
import { Card, CardHeader } from '@mui/material'
import { AppOrderTimeline } from '../sections/@dashboard/app'

// components
import Page from '../components/Page'
import swal2 from 'sweetalert2'
import '../css/style.css'
import UserId from '../../src/components/Auth/UserId'
import UserFullName from 'src/components/Auth/UserFullName'
import UserManager from 'src/components/Auth/UserManager'
import CircularProgress from '@mui/material/CircularProgress'
import Tooltip from '@mui/material/Tooltip';
import UserManagerName from 'src/components/Auth/UserManagerName'


// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}))

// ----------------------------------------------------------------------
const MyEmotions = () => {
  const [voteStatusText, setVoteStatusText] = useState(
      'Fetching your vote status'
  )
  const [voteStatus, setVoteStatus] = useState('Loading')
  const [reportStatus, setReportStatus] = useState('Loading')
  const [refresh, setRefresh] = useState(false)

  const [rawEmployeeReport, setRawEmployeeReport] = useState(null)
  const [employeeReport, setEmployeeReport] = useState([])
  const [employeeReportList, setEmployeeReportList] = useState([])
  const [sortedEmployeeReport, setSortedEmployeeReport] = useState(null)
  const currentUser = UserId()
  const userName = UserFullName()
  const managerInfo = UserManager()
  const managerName = UserManagerName()

  let size = managerInfo.length
  let managerAlias = managerInfo[size - 1]

  useEffect(() => {
    const fetchVoteStatus = () => {
      if (currentUser !== 'notLoggedInDa') {
        axios
            .get(
                process.env.REACT_APP_SENTIMETER_BACKEND +
                'voteStatus/' +
                currentUser,
                {
                  headers: {
                    authorizationToken: 'ADECT-BLR-TOOLS',
                  },
                }
            )
            .then((res) => {
              const rawVoteStatus = res.data
              setVoteStatus(
                  rawVoteStatus
                      ? rawVoteStatus.includes('day')
                          ? 'Voted'
                          : 'NotVoted'
                      : 'NotVoted'
              )
              setVoteStatusText(
                  rawVoteStatus
                      ? rawVoteStatus.includes('day')
                          ? 'You already voted for the day!'
                          : 'Please vote! How do you feel today?'
                      : 'Please vote! How do you feel today?'
              )
            })
      }
    }
    fetchVoteStatus()
  }, [currentUser, refresh])

  useEffect(() => {
    const fetchEmployeeReport = async () => {
      axios
          .get(
              process.env.REACT_APP_SENTIMETER_BACKEND +
              'employeeReport/' +
              currentUser,
              {
                headers: {
                  authorizationToken: 'ADECT-BLR-TOOLS',
                },
              }
          )
          .then((res) => {
            const rawData = res.data
            setRawEmployeeReport(rawData)
            setReportStatus('Loaded')
            if (rawData) {
              setRawEmployeeReport(rawData)
            }
          })
    }
    fetchEmployeeReport()
  }, [currentUser, refresh, voteStatus])

  useEffect(() => {
    const sortEmployeeReport = () => {
      if (rawEmployeeReport) {
        let sortedReport = []
        sortedReport = rawEmployeeReport.sort(function (a, b) {
          return a.voteDate.localeCompare(b.voteDate)
        })
        setSortedEmployeeReport(sortedReport)
      }
    }
    sortEmployeeReport()
  }, [rawEmployeeReport])

  useEffect(() => {
    const formatEmployeeReport = () => {
      if (sortedEmployeeReport) {
        let formattedReport = []
        formattedReport = sortedEmployeeReport.map((item, index) => {
          return {
            item: {
              id: index,
              time: sortedEmployeeReport[index].voteDate,
              title: sortedEmployeeReport[index].emotion,
              type: sortedEmployeeReport[index].emotion,
            },
            isLast: compareDate(
                getMaxDate(sortedEmployeeReport),
                sortedEmployeeReport[index].voteDate
            ),
          }
        })
        setEmployeeReport(formattedReport)
      }
    }
    formatEmployeeReport()
  }, [sortedEmployeeReport])

  //Compare Date
  const compareDate = (mDate, uDate) => {
    let maxDate = formatDate(new Date(mDate))
    let userDate = formatDate(new Date(uDate))
    let flag
    if (maxDate === userDate) {
      flag = true
    } else {
      flag = false
    }
    return flag
  }

  //Max Date
  const getMaxDate = (data) => {
    var newObject = {}
    var dates = data.map(function (obj) {
      var regEx = new RegExp(/-/g)
      //Convert date to dateKey
      var dateKey = parseInt(obj.voteDate.replace(regEx, ''), 10)
      newObject[dateKey] = obj
      return dateKey
    })
    return newObject[Math.max(...dates)].voteDate
  }

  //formatDate
  const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  useEffect(() => {
    const setupEmployeeReportList = async () => {
      let employeeReportList = []
      employeeReportList = employeeReport.map((item, index) => ({
        id: employeeReport[index].item.id,
        title: employeeReport[index].item.title,
        type: employeeReport[index].item.time,
        time: employeeReport[index].item.time,
      }))
      setEmployeeReportList(employeeReportList)
    }
    setupEmployeeReportList()
  }, [employeeReport])

  const handleVoteInput = (event) => {
    const userVote = event.currentTarget.id

    let body = {
      employeeAlias: currentUser,
      employeeName: userName,
      managerAlias: managerAlias,
      managerName: managerName,
      emotion: userVote,
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        authorizationToken: 'ADECT-BLR-TOOLS',
      },
      body: JSON.stringify({
        employeeAlias: currentUser,
        employeeName: userName,
        managerAlias: managerAlias,
        managerName: managerName,
        emotion: userVote,
      }),
    }

    swal2
        .fire({
          title: 'Are you sure?',
          text: 'This will save your vote for the day!!',
          icon: 'warning',
          width: '450px',
          height: '350px',
          showCancelButton: true,
          confirmButtonColor: '#73C959',
          cancelButtonColor: '#E44741',
          confirmButtonText: 'Vote!',
          customClass: {
            position: 'center',
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            setVoteStatus('Loading')
            setReportStatus('Loading')
            setVoteStatusText('saving your vote..')
            fetch(
                process.env.REACT_APP_SENTIMETER_BACKEND + 'saveEmployeeVote',
                requestOptions
            ).then((response) => {
              if (response.status === 200) {
                setVoteStatus('Voted')
                setVoteStatusText('Your vote is saved, thank you!')
              } else {
                setVoteStatus('NotVoted')
                setVoteStatusText('UnSuccessful, please try again later!')
              }
            })
          }
          setRefresh('Refresh')
        })
  }
  const VoteCard = (
      <div>
        <div hidden={voteStatus !== 'Loading'}>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        </div>
        <div hidden={voteStatus !== 'Voted'}>
            <Button
              variant="text"
              name="voted"
              sx={{
                height: 60,
                fontSize: 15,
                borderRadius: 25,
              }}
          >
            <FontAwesomeIcon icon={faCircleCheck} size="5x" />
          </Button>
        </div>
        <Box hidden={voteStatus !== 'NotVoted'} >

            <Grid container>
              <Grid item xs={5} md={6} lg={15}>
                <Box sx={{ display: 'flex', justifyContent:'center'}}>
                  <Stack
                      direction="row"
                      height="120px"
                      marginTop="1px"
                  >
                    <Card className="card" id="Typical" onClick={handleVoteInput}>
                      <CardHeader  className="cardHeader" title="Typical"></CardHeader>
                      <CardContent className="cardContent"> It is a normal day at work </CardContent>
                    </Card>
                    <Card className="card" id="Ideal" onClick={handleVoteInput}>
                        <CardHeader className="cardHeader" title="Ideal"></CardHeader>
                        <CardContent className="cardContent">It is a perfect day at work </CardContent>
                    </Card>
                    <Card className='card' id='Crisis' onClick={handleVoteInput} >
                      <CardHeader className="cardHeader" title="Crisis"></CardHeader>
                      <CardContent className="cardContent">It is a difficult day at work </CardContent>
                    </Card>

                  </Stack>
                </Box>
                <Box sx={{ display: 'flex', justifyContent:'center'}}>
                  <Stack
                      direction="row"
                      height="90px"
                      marginTop="10px"

                  >
                    <Card className='card' onClick={handleVoteInput} id='Disengaged'>
                      <CardHeader className="cardHeader" title="Disengaged"></CardHeader>
                      <CardContent className="cardContent">I don't feel involved at work</CardContent>
                    </Card>

                    <Card className='card' onClick={handleVoteInput} id='Toxic'>
                      <CardHeader className="cardHeader" title="Toxic" ></CardHeader>
                      <CardContent className="cardContent">I am not liking the work environment</CardContent>
                    </Card>
                  </Stack>
                </Box>
              </Grid>

            </Grid>

        </Box>
      </div>
  )

  return (
      <Page title="ADECT - SENTIMETER">
        <Container>
          <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
            <Typography variant="h3" paragraph>
              Welcome to Sentimeter!
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Capturing emotional well-being.
            </Typography>
          </ContentStyle>
          <br></br>

          <Grid item xs={12} md={6} lg={8}>
            <Card>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CardHeader title={voteStatusText} textAlign="center" />
              </Box>
              <Grid container>

                <Grid item xs={12} md={6} lg={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Stack
                        spacing={5}
                        direction="row"
                        height="270px"
                        marginTop="15px"
                    >
                      {VoteCard}
                    </Stack>
                  </Box>
                </Grid>

              </Grid>
            </Card>
          </Grid>
          <br></br>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Box component="img" src="/static/illustrations/Home4.png"/>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <AppOrderTimeline
                  title="Weekly Emotion History"
                  list={employeeReportList}
                  reportStatus={reportStatus}
              />
            </Grid>
          </Grid>

        </Container>
      </Page>


  )
}


export default MyEmotions

