import UserManager from './UserManager'
import ManagerDetails from './UserManager'

const UserManagerName = () => {
  let managerName
  let managerNameArr = []
  const managerInfo = UserManager()
  console.log('ManagerDetails in Manager Name Component', managerInfo)
  let size = managerInfo.length
  if (managerInfo[0] != null) {
    managerNameArr = managerInfo.slice(0, -1)
    managerName = Array.isArray(managerNameArr) ? managerNameArr.join(',') : ''
    return managerName
  } else {
    console.log('PrintingUserFullName: null')
    return null
  }
}

export default UserManagerName
