import React, { useState, useEffect } from 'react'
import axios, { AxiosResponse, AxiosError } from 'axios'
// @mui
import { useTheme } from '@mui/material/styles'
import { Grid, Container, Typography, Box, Button, Stack } from '@mui/material'
// components
import Page from '../components/Page'
// sections
import { AppCurrentVisits, AppWebsiteVisits } from '../sections/@dashboard/app'
import 'font-awesome/css/font-awesome.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
var XLSX = require('xlsx')

// ----------------------------------------------------------------------

//Report handler for week

function getConstructedChartData(response) {
  const obj1 = {}
  response.forEach((item) => {
    if (!obj1[item.voteDate]) {
      obj1[item.voteDate] = {
        Typical: 0,
        Ideal: 0,
        Crisis: 0,
        Disengaged: 0,
        Toxic:0
      }
      obj1[item.voteDate][item.emotion] = 1
    } else {
      obj1[item.voteDate][item.emotion] = obj1[item.voteDate].hasOwnProperty(
        item.emotion
      )
        ? obj1[item.voteDate][item.emotion] + 1
        : 1
    }
  })
  const chartLabels1 = Object.keys(obj1)
  const emotionObj1 = {}
  chartLabels1.forEach((date) => {
    Object.keys(obj1[date]).forEach((emotion) => {
      emotionObj1[emotion] = emotionObj1.hasOwnProperty(emotion)
        ? [...emotionObj1[emotion], obj1[date][emotion]]
        : [obj1[date][emotion]]
    })
  })

  const chartData = [
    {
      name: 'Typical',
      type: 'area',
      fill: 'gradient',
      data: emotionObj1 ? emotionObj1.Typical : 0,
    },
    {
      name: 'Ideal',
      type: 'line',
      fill: 'solid',
      data: emotionObj1 ? emotionObj1.Ideal : 0,
    },
    {
      name: 'Crisis',
      type: 'line',
      fill: 'solid',
      data: emotionObj1 ? emotionObj1.Crisis : 0,
    },
    {
      name: 'Disengaged',
      type: 'line',
      fill: 'solid',
      data: emotionObj1 ? emotionObj1.Disengaged : 0,
    },
    {
      name: 'Toxic',
      type: 'line',
      fill: 'solid',
      data: emotionObj1 ? emotionObj1.Toxic : 0,
    },
  ]

  return {
    chartLabels1,
    chartData,
  }
}

function sortDate(data) {
  let tempData = []
  tempData = data.sort(function (a, b) {
    return a.voteDate.localeCompare(b.voteDate)
  })
  return tempData
}

export default function TeamEmotions() {
  const [weeklyReport, setWeeklyReport] = useState([])
  const [currentTrend, setCurrentTrend] = useState([])
  const theme = useTheme()

  useEffect(() => {
    getWeeklyReport()
    getCurrentTrend()
  }, [])

  const getWeeklyReport = async () => {
    axios
      .get(process.env.REACT_APP_SENTIMETER_BACKEND + 'weeklyReport/', {
        headers: {
          authorizationToken: 'ADECT-BLR-TOOLS',
        },
      })
      .then((res) => {
        const reportData = res.data
        setWeeklyReport(sortDate(reportData))
      })
  }

  let reportDataNew = getConstructedChartData(weeklyReport)

  const getCurrentTrend = async () => {
    axios
      .get(process.env.REACT_APP_SENTIMETER_BACKEND + 'currentDayTrend/', {
        headers: {
          authorizationToken: 'ADECT-BLR-TOOLS',
        },
      })
      .then((res) => {
        const currentTrendData = res.data
        setCurrentTrend(getConstructedCurrentChartData(currentTrendData))
      })
  }

  //Report handler for current date
  function getConstructedCurrentChartData(response) {
    let formattedData = {}
    if (response) {
      response.forEach((item) => {
        if (!formattedData[item.voteDate]) {
          formattedData[item.voteDate] = {
            Typical: 0,
            Ideal: 0,
            Crisis: 0,
            Disengaged: 0,
            Toxic: 0
          }
          formattedData[item.voteDate][item.emotion] = 1
        } else {
          formattedData[item.voteDate][item.emotion] = formattedData[
            item.voteDate
          ].hasOwnProperty(item.emotion)
            ? formattedData[item.voteDate][item.emotion] + 1
            : 1
        }
      })
    }

    let keys = formattedData && Object.keys(formattedData)
    if (formattedData) {
      let chartData = [
        {
          label: 'Typical',
          value: formattedData[keys].Typical ? formattedData[keys].Typical : 0,
        },
        {
          label: 'Ideal',
          value: formattedData[keys].Ideal ? formattedData[keys].Ideal : 0,
        },
        {
          label: 'Crisis',
          value: formattedData[keys].Crisis ? formattedData[keys].Crisis : 0,
        },
        {
          label: 'Disengaged',
          value: formattedData[keys].Disengaged ? formattedData[keys].Disengaged : 0,
        },
        {
          label: 'Toxic',
          value: formattedData[keys].Toxic ? formattedData[keys].Toxic : 0,
        },
      ]
      return chartData
    } else {
      let chartData = [
        {
          label: 'Typical',
          value: 0,
        },
        {
          label: 'Ideal',
          value: 0,
        },
        {
          label: 'Crisis',
          value: 0,
        },
        {
          label: 'Disengaged',
          value: 0,
        },
        {
          label: 'Toxic',
          value: 0,
        },
      ]
      return chartData
    }
  }

  //Download Weekly Report

  const handleDownload = (event) => {
    const downloadAction = event.currentTarget.value
    if (downloadAction) {
      const worksheet = XLSX.utils.json_to_sheet(weeklyReport)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
      XLSX.writeFile(workbook, 'SentimeterWeeklyReport.xlsx')
    }
  }

  return (
    <Page title="Admin - SENTIMETER">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" sx={{ mb: 5 }}>
            Hi, Welcome back
          </Typography>
          <Button
            variant="text"
            value="Download"
            name="Download"
            id="Download"
            text="Download"
            onClick={handleDownload}
          >
            <FontAwesomeIcon icon={faDownload} size="2x" />
          </Button>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Weekly Trends"
              chartLabels={reportDataNew.chartLabels1}
              chartData={reportDataNew.chartData}
              chartColors={[
                theme.palette.chart.green[3],
                theme.palette.chart.green[0],
                theme.palette.chart.yellow[1],
                theme.palette.chart.grey[0],
                theme.palette.chart.pink[1],
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Current Trends"
              chartData={currentTrend}
              chartColors={[
                theme.palette.chart.green[3],
                theme.palette.chart.green[0],
                theme.palette.chart.yellow[1],
                theme.palette.chart.grey[0],
                theme.palette.chart.pink[1],
              ]}
            />
          </Grid>
        </Grid>
        <Box component="img" src="/static/illustrations/Home4.png" />
      </Container>
    </Page>
  )
}
